import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src='/pizzasim.png' className="App-logo "alt="logo" />
        <p>
          Tastes approximately like pizza!
        </p>
      </header>
    </div>
  );
}

export default App;
